import React from "react";
import { Roles } from "../../../constant/Constant";
import { useNavigate } from "react-router-dom";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardProfileCard = ({ dashboardData }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/profile")}
      className="w-full lg:w-1/4 bg-[#DCF0FF] rounded-md col-span-2 row-span-2 flex flex-col gap-2 md:5 lg:gap-7 xl:gap-2 items-center space-y-1 p-4 cursor-pointer"
    >
      <div className="w-10 h-10 md:w-14 md:h-14 xl:w-20 xl:h-20 2xl:w-24  2xl:h-24 border border-slate-300 rounded-full bg-custom-white">
        <img
          src={
            dashboardData?.user?.avatar
              ? `${Base_url}/${dashboardData?.user?.avatar}`
              : `https://api.dicebear.com/5.x/initials/svg?seed=${
                  dashboardData?.user?.user_name ?? "--"
                }`
          }
          alt={`${dashboardData?.user?.user_name}`}
          className="w-full h-full rounded-full p-1"
        />
      </div>
      <div className="flex flex-col text-left gap-1">
        <span className="font-semibold">
          Name - {dashboardData?.user?.user_name ?? ""}
          <span className="text-xs text-slate-400 font-semibold">
            (
            {dashboardData?.user?.designation
              ? dashboardData?.user?.designation
              : dashboardData?.user?.role === 2
              ? "Admin"
              : ""}
            )
          </span>
        </span>
        <span className="text-slate-400 text-sm font-semibold">
          Email - {dashboardData?.user?.email ?? ""}
        </span>
        <span className="text-slate-400 text-sm font-semibold">
          Mobile -{" "}
          {(dashboardData?.user?.country_code ?? "") +
            " " +
            (dashboardData?.user?.mobile ?? "")}
        </span>
        <span className="text-slate-400 text-sm capitalize font-semibold">
          Role -
          {Object.keys(Roles)
            ?.find((key) => Roles[key] === dashboardData?.user?.role)
            ?.replace("_", " ")}
        </span>
        <span className="text-slate-400 text-sm font-semibold">
          EmployeeId - {dashboardData?.user?.employee_id ?? ""}
        </span>
      </div>
    </div>
  );
};

export default DashboardProfileCard;
