import React from "react";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardJoiningCard = ({ groupedJoinings }) => {
  return (
    <div className="rounded-md p-2 row-span-1">
      <div className="w-full h-64 overflow-auto">
        {Object?.entries(groupedJoinings)?.length > 0 ? (
          Object?.entries(groupedJoinings)?.map(([date, joinings]) => (
            <div key={date}>
              <h2 className="font-semibold text-xs flex items-center my-2">
                {date}
              </h2>
              {joinings?.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    joinings?.length - 1 !== index &&
                    "border-b border-[#EFE9CF]"
                  }  w-full py-2 space-y-2 flex justify-between bg-[#FAFAFA] rounded-md pr-2`}
                >
                  <div className="flex items-center gap-4">
                    <div className="w-10 h-10  rounded-full bg-custom-white">
                      <img
                        src={
                          item?.avatar
                            ? `${Base_url}/${item?.avatar}`
                            : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
                        }
                        alt={`${item?.user_name}`}
                        className="w-full h-full rounded-full p-1"
                      />
                    </div>
                    <div className="flex flex-col text-left gap-1">
                      <span className="font-semibold">{item?.user_name}</span>
                      <span className="text-xs text-slate-400 font-semibold">
                        {item?.designation
                          ? item?.designation?.designation
                          : "Admin"}
                      </span>
                    </div>
                  </div>
                  <span className="text-xs text-slate-400 font-semibold">
                    {item?.eventType ?? "--"}
                  </span>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center font-semibold text-sm ">
            No Data available
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardJoiningCard;
