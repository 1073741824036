import React from "react";
import { dateFormatter } from "../../../helper/formatDate";
const Base_url = process.env.REACT_APP_BASE_URL;

const DashboardMeetingCard = ({ meetingsData }) => {
  return (
    <div className="w-full h-52 overflow-auto mt-2">
      {meetingsData?.length
        ? meetingsData?.map((item) => (
            <div
              key={item?._id}
              className={` w-full p-2 space-y-2 bg-[#FAFAFA] rounded-md mb-2`}
            >
              <div className="text-sm font-semibold">{item?.title ?? "--"}</div>
              <div className="flex justify-between items-center text-xs font-semibold">
                <span>
                  {dateFormatter(item?.date_time, {
                    format: "MMM DD,YYYY HH:MM:A",
                    includeTimeForToday: true,
                  })}
                </span>
                <span>
                  Duration - {item?.meeting_duration}{" "}
                  {item?.meeting_duration?.split(":")[0] >= 1 ? "Hrs" : "Min"}
                </span>
              </div>
            </div>
          ))
        : "No Upcoming Meetings"}
    </div>
  );
};

export default DashboardMeetingCard;
