import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CustomButton, CustomInput, CustomModal } from "../common";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../context";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import {
  attendanceEndPoints,
  meetingEndPoints,
} from "../../networking/Endpoints";
import MeetingForm from "./MeetingForm";
import MeetingView from "./MeetingView";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { dateFormatter } from "../../helper/formatDate";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const RejectMeetingValidation = yup.object().shape({
  reason: yup.string().required("Reason is required").trim(),
});

const Meetings = () => {
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    dispatch = useDispatch(),
    { setToastNotification } = useContext(CustomAlertContext),
    [meetingsData, setMeetingsData] = useState([]),
    [attendanceData, setAttendanceData] = useState([]),
    localizer = dayjsLocalizer(dayjs),
    meetingFormRef = useRef(),
    meetingViewRef = useRef(),
    [showMeetingForm, setShowMeetingForm] = useState(false),
    [showMeetingView, setShowMeetingView] = useState(false),
    [showRejectModal, setShowRejectModal] = useState(false),
    [meetingDetails, setMeetingDetails] = useState(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedView, setSelectedView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("month");

  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
  const onView = useCallback((newView) => setView(newView), [setView]);
  const handleDayView = (date) => {
    setCurrentDate(date);
    setSelectedView("day");
  };
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RejectMeetingValidation),
  });
  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
  };
  const GetAllMeetings = async () => {
    dispatch(setLoading(true));
    try {
      const meeting_response = await apiConnector(
        "GET",
        meetingEndPoints?.MEETING_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setMeetingsData(meeting_response?.data?.meetings);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const GetAttendance = async () => {
    dispatch(setLoading(true));
    try {
      const working_response = await apiConnector(
        "GET",
        attendanceEndPoints?.WORKING_HOURS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      setAttendanceData(working_response?.data?.attendanceData);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  console.log(attendanceData, "attendanceData");

  useEffect(() => {
    GetAllMeetings();
    GetAttendance();
  }, [token]);

  const MeetingStatusHandler = async (payload) => {
    dispatch(setLoading(true));
    try {
      const meeting_response = await apiConnector(
        "PUT",
        `${meetingEndPoints?.MEETING_STATUS_UPDATE_API}/${meetingDetails?._id}`,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(meeting_response?.data?.message, "success");
      GetAllMeetings();
      if (meetingDetails) {
        setShowMeetingView(false);
      }
      setShowRejectModal(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const meetingRejectHandler = async (data) => {
    let payload = {
      invitation: "rejected",
      reason: data?.reason,
    };
    MeetingStatusHandler(payload);
  };
  const attendanceEvents = attendanceData?.map((item) => {
    const attendance_date_time = dayjs(item?.date_time);

    // Split meeting_duration into hours and minutes
    // const [durationHours, durationMinutes] = item?.meeting_duration
    //   ?.split(":")
    //   .map(Number) || [0, 0];

    return {
      title: item?.loggedHoures + " Total Working Hours" || "",
      start: new Date(
        attendance_date_time.year(),
        attendance_date_time.month(),
        attendance_date_time.date(),
        0,
        0,
        0
      ),
      end: new Date(
        attendance_date_time.year(),
        attendance_date_time.month(),
        attendance_date_time.date(),
        0,
        0,
        0
      ),
      meetingEvent: null,
    };
  });
  console.log(attendanceEvents, "attendanceEvents");

  const meetingEvents = meetingsData?.map((item) => {
    const meeting_date_time = dayjs(item?.date_time);

    // Split meeting_duration into hours and minutes
    const [durationHours, durationMinutes] = item?.meeting_duration
      ?.split(":")
      .map(Number) || [0, 0];

    return {
      title: item?.title || "Meeting",
      start: new Date(
        meeting_date_time.year(),
        meeting_date_time.month(),
        meeting_date_time.date(),
        meeting_date_time.hour(),
        meeting_date_time.minute(),
        meeting_date_time.second()
      ),
      end: new Date(
        meeting_date_time.year(),
        meeting_date_time.month(),
        meeting_date_time.date(),
        meeting_date_time.hour() + durationHours,
        meeting_date_time.minute() + durationMinutes,
        meeting_date_time.second()
      ),
      meetingEvent: item,
    };
  });
  const calenderEvent = [...meetingEvents, ...attendanceEvents];
  return (
    <div className="w-full bg-custom-white p-4">
      <section className="flex justify-between items-center mb-2">
        <h2 className="font-semibold">Calender</h2>
        <CustomButton
          title={
            <div className="flex justify-center items-center gap-2 ">
              <FaPlusCircle />
              <span>Request Meeting</span>
            </div>
          }
          buttonType="button"
          onClick={() => {
            setShowMeetingView(false);
            setShowMeetingForm((prev) => !prev);
          }}
          classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
        />
      </section>
      <div className="myCustomHeight">
        <Calendar
          localizer={localizer}
          events={calenderEvent ?? []}
          style={{ height: "75vh" }}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={(event) => {
            if (event?.meetingEvent) {
              setMeetingDetails(event?.meetingEvent);
              setShowMeetingView((prev) => !prev);
            }
          }}
          onSelectSlot={handleSelect}
          onNavigate={onNavigate}
          onView={onView}
          view={view}
          dayLayoutAlgorithm={"no-overlap"}
          eventPropGetter={(event) => {
            const customStyle = {
              backgroundColor: event.meetingEvent
                ? event.meetingEvent.participants?.some(
                    (item) =>
                      item?.user?._id === user?._id &&
                      item?.is_invitation_accepted === true
                  )
                  ? "#28a745"
                  : event.meetingEvent.participants?.some(
                      (item) =>
                        item?.user?._id === user?._id &&
                        item?.is_invitation_accepted === false
                    )
                  ? "#FF0B0B"
                  : "#2780C1"
                : "#ffb6c1",
              color: "#fff",
              borderRadius: "5px",
              border: "none",
              padding: "5px",
            };
            return { style: customStyle };
          }}
          // components={{
          //   event: ({ event }) => (
          //     <div>
          //       <strong className="text-sm font-semibold">{event.title}</strong>
          //       <div className="text-xs font-semibold">
          //         Date & Time -{" "}
          //         {dateFormatter(event.meetingEvent?.date_time, {
          //           format: "MMM DD,YYYY HH:MM:A",
          //           includeTimeForToday: true,
          //         })}
          //       </div>
          //       <div className="text-xs font-semibold">
          //         Duration - {event.meetingEvent?.meeting_duration}{" "}
          //         {event.meetingEvent?.meeting_duration?.split(":")[0] >= 1
          //           ? "Hrs"
          //           : "Min"}
          //       </div>
          //     </div>
          //   ),
          // }}
        />
      </div>
      <CustomModal
        isOpen={showMeetingView}
        onClose={() => setShowMeetingView(false)}
        ref={meetingViewRef}
        classname={`w-11/12 lg:w-2/3`}
      >
        <MeetingView
          setShowMeetingForm={setShowMeetingForm}
          meetingDetails={meetingDetails}
          setShowMeetingView={setShowMeetingView}
          MeetingStatusHandler={MeetingStatusHandler}
          setMeetingDetails={setMeetingDetails}
          setShowRejectModal={setShowRejectModal}
        />
      </CustomModal>
      <CustomModal
        isOpen={showMeetingForm}
        onClose={() => setShowMeetingForm(false)}
        ref={meetingFormRef}
        classname={`lg:w-2/3`}
      >
        <MeetingForm
          meetingDetails={showMeetingView ? meetingDetails : null}
          setShowMeetingForm={setShowMeetingForm}
          setShowMeetingView={setShowMeetingView}
          FetchAllMeetings={GetAllMeetings}
        />
      </CustomModal>
      <CustomModal
        isOpen={showRejectModal}
        onClose={() => setShowRejectModal(false)}
      >
        <div className="text-center text-custom-black">
          <h2 className="text-2xl font-bold my-4">Reason?</h2>
        </div>
        <form
          className="w-full "
          noValidate
          onSubmit={handleSubmit(meetingRejectHandler)}
        >
          <div className="my-2">
            <CustomInput
              name="reason"
              label="Reason"
              inputType="text-area"
              placeholder="Type Here..."
              control={control}
              error={errors?.reason?.message}
            />
          </div>
          <div className="flex justify-end items-center gap-5 mt-5">
            <CustomButton
              title={"Cancel"}
              onClick={() => setShowRejectModal(false)}
              buttonType={"button"}
              classname={"px-2 py-1 bg-custom-white border rounded-md "}
            />
            <CustomButton
              title={"Reject Meeting"}
              buttonType={"submit"}
              disabled={loading}
              classname={"bg-[#FB0F0F] text-custom-white px-2 py-1 rounded-md"}
            />
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default Meetings;
