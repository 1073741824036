import React, { useContext, useEffect, useState } from "react";
import { CustomButton, CustomInput, CustomSelect } from "../common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { statusOptions } from "../../constant/Constant";
import { setLoading } from "../../redux/slice/loadingSlice";
import { projectEndPoints } from "../../networking/Endpoints";
import { apiConnector } from "../../networking/ApiConnector";
import { CustomAlertContext } from "../../context";
import { setActiveTabInProject } from "../../redux/slice/projectSlice";

const ProjectSheet = ({ project, projctDetailName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [projectImportantDetails, setProjectImportantDetails] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: initialValues,
  });
  useEffect(() => {
    reset({
      business_requirement_details_status:
        projectImportantDetails?.business_requirement_details_status
          ? projectImportantDetails?.business_requirement_details_status
          : undefined,
      business_requirement_details_link:
        projectImportantDetails?.business_requirement_details_link
          ? projectImportantDetails?.business_requirement_details_link
          : undefined,
      business_logic_development_status:
        projectImportantDetails?.business_logic_development_status
          ? projectImportantDetails?.business_logic_development_status
          : undefined,
      business_logic_development_link:
        projectImportantDetails?.business_logic_development_link
          ? projectImportantDetails?.business_logic_development_link
          : undefined,

      // Designing
      design_frontend: projectImportantDetails?.designing[0]?.design_tech,
      design_frontend_status:
        projectImportantDetails?.designing[0]?.design_status,
      design_frontend_link: projectImportantDetails?.designing[0]?.design_link,
      design_backend: projectImportantDetails?.designing[1]?.design_tech,
      design_backend_status:
        projectImportantDetails?.designing[1]?.design_status,
      design_backend_link: projectImportantDetails?.designing[1]?.design_link,
      design_mobile_app: projectImportantDetails?.designing[2]?.design_tech,
      design_mobile_app_status:
        projectImportantDetails?.designing[2]?.design_status,
      design_mobile_app_link:
        projectImportantDetails?.designing[2]?.design_link,

      // Development
      development_user_side_backend:
        projectImportantDetails?.development[0]?.details?.backend?.tech,
      development_user_side_backend_status:
        projectImportantDetails?.development[0]?.details?.backend?.status,
      development_user_side_backend_link:
        projectImportantDetails?.development[0]?.details?.backend?.link,
      development_user_side_frontend:
        projectImportantDetails?.development[0]?.details?.frontend?.tech,
      development_user_side_frontend_status:
        projectImportantDetails?.development[0]?.details?.frontend?.status,
      development_user_side_frontend_link:
        projectImportantDetails?.development[0]?.details?.frontend?.link,
      development_user_side_mobile_app:
        projectImportantDetails?.development[0]?.details?.mobile?.tech,
      development_user_side_mobile_app_status:
        projectImportantDetails?.development[0]?.details?.mobile?.status,
      development_user_side_mobile_app_link:
        projectImportantDetails?.development[0]?.details?.mobile?.status,

      development_admin_backend:
        projectImportantDetails?.development[1]?.details?.backend?.tech,
      development_admin_backend_status:
        projectImportantDetails?.development[1]?.details?.backend?.status,
      development_admin_backend_link:
        projectImportantDetails?.development[1]?.details?.backend?.link,
      development_admin_frontend:
        projectImportantDetails?.development[1]?.details?.frontend?.tech,
      development_admin_frontend_status:
        projectImportantDetails?.development[1]?.details?.frontend?.status,
      development_admin_frontend_link:
        projectImportantDetails?.development[1]?.details?.frontend?.link,
      development_admin_mobile_app:
        projectImportantDetails?.development[1]?.details?.mobile?.tech,
      development_admin_mobile_app_status:
        projectImportantDetails?.development[1]?.details?.mobile?.status,
      development_admin_mobile_app_link:
        projectImportantDetails?.development[1]?.details?.mobile?.link,

      // Deployment
      deployment_user_side_backend:
        projectImportantDetails?.deployement[0]?.details?.backend?.tech,
      deployment_user_side_backend_status:
        projectImportantDetails?.deployement[0]?.details?.backend?.status,
      deployment_user_side_backend_link:
        projectImportantDetails?.deployement[0]?.details?.backend?.link,
      deployment_user_side_frontend:
        projectImportantDetails?.deployement[0]?.details?.frontend?.tech,
      deployment_user_side_frontend_status:
        projectImportantDetails?.deployement[0]?.details?.frontend?.status,
      deployment_user_side_frontend_link:
        projectImportantDetails?.deployement[0]?.details?.frontend?.link,
      deployment_user_side_mobile_app:
        projectImportantDetails?.deployement[0]?.details?.mobile?.tech,
      deployment_user_side_mobile_app_status:
        projectImportantDetails?.deployement[0]?.details?.mobile?.status,
      deployment_user_side_mobile_app_link:
        projectImportantDetails?.deployement[0]?.details?.mobile?.link,

      deployment_admin_backend:
        projectImportantDetails?.deployement[1]?.details?.mobile?.tech,
      deployment_admin_backend_status:
        projectImportantDetails?.deployement[1]?.details?.mobile?.status,
      deployment_admin_backend_link:
        projectImportantDetails?.deployement[1]?.details?.mobile?.link,
      deployment_admin_frontend:
        projectImportantDetails?.deployement[1]?.details?.mobile?.tech,
      deployment_admin_frontend_status:
        projectImportantDetails?.deployement[1]?.details?.mobile?.status,
      deployment_admin_frontend_link:
        projectImportantDetails?.deployement[1]?.details?.mobile?.link,
      deployment_admin_mobile_app:
        projectImportantDetails?.deployement[1]?.details?.mobile?.tech,
      deployment_admin_mobile_app_status:
        projectImportantDetails?.deployement[1]?.details?.mobile?.status,
      deployment_admin_mobile_app_link:
        projectImportantDetails?.deployement[1]?.details?.mobile?.link,
    });
  }, [projectImportantDetails]);

  const fetchProjectImportantDetails = async () => {
    dispatch(setLoading(true));
    try {
      const project_details_response = await apiConnector(
        "GET",
        `${projectEndPoints?.PROJECT_DETAILS_API}/${project?._id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setProjectImportantDetails(project_details_response?.data?.projectDetail);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (projctDetailName === "Edit Project Sheet" && project) {
      fetchProjectImportantDetails();
    }
  }, [project]);
  const onSubmitHandler = async (data) => {
    let payload = {
      business_requirement_details_status:
        data?.business_requirement_details_status,
      business_requirement_details_link:
        data?.business_requirement_details_link,
      business_logic_development_status:
        data?.business_logic_development_status,
      business_logic_development_link: data?.business_logic_development_link,
      designing: [
        {
          title: "Frontend",
          design_tech: data?.design_frontend,
          design_status: data?.design_frontend_status,
          design_link: data?.design_frontend_link,
        },
        {
          title: "Backend",
          design_tech: data?.design_backend,
          design_status: data?.design_backend_status,
          design_link: data?.design_backend_link,
        },
        {
          title: "Mobile",
          design_tech: data?.design_mobile_app,
          design_status: data?.design_mobile_app_status,
          design_link: data?.design_mobile_app_link,
        },
      ],
      development: [
        {
          title: "User Side",
          details: {
            frontend: {
              tech: data?.development_user_side_frontend,
              status: data?.development_user_side_frontend_status,
              link: data?.development_user_side_frontend_link,
            },
            backend: {
              tech: data?.development_user_side_backend,
              status: data?.development_user_side_backend_status,
              link: data?.development_user_side_backend_link,
            },
            mobile: {
              tech: data?.development_user_side_mobile_app,
              status: data?.development_user_side_mobile_app_status,
              link: data?.development_user_side_mobile_app_link,
            },
          },
        },
        {
          title: "Admin Console",
          details: {
            frontend: {
              tech: data?.development_admin_frontend,
              status: data?.development_admin_frontend_status,
              link: data?.development_admin_frontend_link,
            },
            backend: {
              tech: data?.development_admin_backend,
              status: data?.development_admin_backend_status,
              link: data?.development_admin_backend_link,
            },
            mobile: {
              tech: data?.development_admin_mobile_app,
              status: data?.development_admin_mobile_app_status,
              link: data?.development_admin_mobile_app_link,
            },
          },
        },
      ],
      deployement: [
        {
          title: "User Side",
          details: {
            frontend: {
              tech: data?.deployment_user_side_frontend,
              status: data?.deployment_user_side_frontend_status,
              link: data?.deployment_user_side_frontend_link,
            },
            backend: {
              tech: data?.deployment_user_side_backend,
              status: data?.deployment_user_side_backend_status,
              link: data?.deployment_user_side_backend_link,
            },
            mobile: {
              tech: data?.deployment_user_side_mobile_app,
              status: data?.deployment_user_side_mobile_app_status,
              link: data?.deployment_user_side_mobile_app_link,
            },
          },
        },
        {
          title: "Admin Console",
          details: {
            frontend: {
              tech: data?.deployment_admin_frontend,
              status: data?.deployment_admin_frontend_status,
              link: data?.deployment_admin_frontend_link,
            },
            backend: {
              tech: data?.deployment_admin_backend,
              status: data?.deployment_admin_backend_status,
              link: data?.deployment_admin_backend_link,
            },
            mobile: {
              tech: data?.deployment_admin_mobile_app,
              status: data?.deployment_admin_mobile_app_status,
              link: data?.deployment_admin_mobile_app_link,
            },
          },
        },
      ],
    };
    dispatch(setLoading(true));
    try {
      const apiMethod =
        projctDetailName === "Edit Project Sheet" ? "PUT" : "POST";
      const apiURl =
        projctDetailName === "Edit Project Sheet"
          ? `${projectEndPoints?.PROJECT_DETAILS_API}/${projectImportantDetails?._id}`
          : `${projectEndPoints?.PROJECT_DETAILS_API}/${project?._id}`;
      const project_details_response = await apiConnector(
        apiMethod,
        apiURl,
        payload,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setToastNotification(project_details_response?.data?.message, "success");
      dispatch(setActiveTabInProject("project_details"));
      reset({});
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="w-full bg-custom-white rounded-md">
      <form
        className="w-full p-6 "
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="text-xl font-semibold text-custom-black">
          {projctDetailName}
        </div>
        <section className="w-full space-y-2 mt-5">
          <div className="text-lg font-semibold text-custom-black">
            1. Documentation
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
            <div className=" col-span-1">
              <CustomSelect
                name="business_requirement_details_status"
                label="Business Requirement Details"
                inputType="text"
                placeholder=""
                control={control}
                options={statusOptions ?? []}
                error={errors?.business_requirement_details_status?.message}
              />
            </div>
            <div className="col-span-2">
              <CustomInput
                name="business_requirement_details_link"
                label="Link"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.business_requirement_details_link?.message}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
            <div className="col-span-1">
              <CustomSelect
                name="business_logic_development_status"
                label="Business Logic Details"
                inputType="text"
                placeholder=""
                control={control}
                options={statusOptions ?? []}
                error={errors?.business_logic_development_status?.message}
              />
            </div>
            <div className=" col-span-2">
              <CustomInput
                name="business_logic_development_link"
                label="Link"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.business_logic_development_link?.message}
              />
            </div>
          </div>
        </section>
        <section className="w-full space-y-2 mt-5">
          <div className="text-lg font-semibold text-custom-black">
            2. Design
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
            <div className=" col-span-1">
              <CustomInput
                name="design_frontend"
                label="Frontend"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.design_frontend?.message}
              />
            </div>
            <div className=" col-span-1">
              <CustomSelect
                name="design_frontend_status"
                label="Status"
                inputType="text"
                placeholder=""
                control={control}
                options={statusOptions ?? []}
                error={errors?.design_frontend_status?.message}
              />
            </div>
            <div className=" col-span-2">
              <CustomInput
                name="design_frontend_link"
                label="Link"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.design_frontend_link?.message}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
            <div className=" col-span-1">
              <CustomInput
                name="design_backend"
                label="Backend"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.design_backend?.message}
              />
            </div>
            <div className=" col-span-1">
              <CustomSelect
                name="design_backend_status"
                label="Status"
                inputType="text"
                placeholder=""
                control={control}
                options={statusOptions ?? []}
                error={errors?.design_backend_status?.message}
              />
            </div>
            <div className=" col-span-2">
              <CustomInput
                name="design_backend_link"
                label="Link"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.design_backend_link?.message}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
            <div className=" col-span-1">
              <CustomInput
                name="design_mobile_app"
                label="Mobile App"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.design_mobile_app?.message}
              />
            </div>
            <div className=" col-span-1">
              <CustomSelect
                name="design_mobile_app_status"
                label="Status"
                inputType="text"
                placeholder=""
                control={control}
                options={statusOptions ?? []}
                error={errors?.design_mobile_app_status?.message}
              />
            </div>
            <div className=" col-span-2">
              <CustomInput
                name="design_mobile_app_link"
                label="Link"
                inputType="text"
                placeholder=""
                control={control}
                error={errors?.design_mobile_app_link?.message}
              />
            </div>
          </div>
        </section>
        <section className="w-full space-y-2 mt-5">
          <div className="text-lg font-semibold text-custom-black">
            3. Development
          </div>
          <div className="w-full">
            <li className="font-semibold text-custom-blue">User Side</li>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="development_user_side_frontend"
                  label="Frontend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_user_side_frontend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="development_user_side_frontend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.development_user_side_frontend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="development_user_side_frontend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_user_side_frontend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="development_user_side_backend"
                  label="Backend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_user_side_backend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="development_user_side_backend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.development_user_side_backend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="development_user_side_backend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_user_side_backend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="development_user_side_mobile_app"
                  label="Mobile App"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_user_side_mobile_app?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="development_user_side_mobile_app_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={
                    errors?.development_user_side_mobile_app_status?.message
                  }
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="development_user_side_mobile_app_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_user_side_mobile_app_link?.message}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <li className="font-semibold text-custom-blue">Admin Console</li>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="development_admin_frontend"
                  label="Frontend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_admin_frontend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="development_admin_frontend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.development_admin_frontend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="development_admin_frontend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_admin_frontend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="development_admin_backend"
                  label="Backend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_admin_backend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="development_admin_backend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.development_admin_backend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="development_admin_backend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_admin_backend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="development_admin_mobile_app"
                  label="Mobile App"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_admin_mobile_app?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="development_admin_mobile_app_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.development_admin_mobile_app_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="development_admin_mobile_app_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.development_admin_mobile_app_link?.message}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="w-full space-y-2 mt-5">
          <div className="text-lg font-semibold text-custom-black">
            4. Deployment
          </div>
          <div className="w-full">
            <li className="font-semibold text-custom-blue">User Console</li>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="deployment_user_side_frontend"
                  label="Frontend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_user_side_frontend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="deployment_user_side_frontend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.deployment_user_side_frontend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="deployment_user_side_frontend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_user_side_frontend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="deployment_user_side_backend"
                  label="Backend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_user_side_backend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="deployment_user_side_backend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.deployment_user_side_backend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="deployment_user_side_backend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_user_side_backend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="deployment_user_side_mobile_app"
                  label="Mobile App"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_user_side_mobile_app?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="deployment_user_side_mobile_app_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={
                    errors?.deployment_user_side_mobile_app_status?.message
                  }
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="deployment_user_side_mobile_app_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_user_side_mobile_app_link?.message}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <li className="font-semibold text-custom-blue">Admin Console</li>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="deployment_admin_frontend"
                  label="Frontend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_admin_frontend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="deployment_admin_frontend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.deployment_admin_frontend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="deployment_admin_frontend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_admin_frontend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="deployment_admin_backend"
                  label="Backend"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_admin_backend?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="deployment_admin_backend_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.deployment_admin_backend_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="deployment_admin_backend_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_admin_backend_link?.message}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-5">
              <div className=" col-span-1">
                <CustomInput
                  name="deployment_admin_mobile_app"
                  label="Mobile App"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_admin_mobile_app?.message}
                />
              </div>
              <div className=" col-span-1">
                <CustomSelect
                  name="deployment_admin_mobile_app_status"
                  label="Status"
                  inputType="text"
                  placeholder=""
                  control={control}
                  options={statusOptions ?? []}
                  error={errors?.deployment_admin_mobile_app_status?.message}
                />
              </div>
              <div className=" col-span-2">
                <CustomInput
                  name="deployment_admin_mobile_app_link"
                  label="Link"
                  inputType="text"
                  placeholder=""
                  control={control}
                  error={errors?.deployment_admin_mobile_app_link?.message}
                />
              </div>
            </div>
          </div>
        </section>

        <div className="flex justify-end items-center my-10 gap-4">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => navigate("/project")}
            classname={`border font-semibold text-custom-black px-5 py-1 rounded-md
            `}
          />
          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.591C100 78.205 77.614 100.591 50 100.591S0 78.205 0 50.591 22.386 0.591 50 0.591 100 22.977 100 50.591ZM9.081 50.591C9.081 73.189 27.401 91.509 50 91.509S90.919 73.189 90.919 50.591 72.599 9.672 50 9.672 9.081 27.992 9.081 50.591Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.968 39.041c2.426-.637 3.896-3.129 3.041-5.486-1.715-4.731-4.137-9.185-7.191-13.206C85.845 15.12 80.883 10.724 75.212 7.413 69.542 4.102 63.275 1.94 56.77 1.051c-5.003-.684-10.072-.604-15.035.228-2.473.414-3.921 2.919-3.284 5.344.637 2.426 3.119 3.849 5.6 3.484 3.801-.559 7.669-.581 11.49-.103 5.324.727 10.453 2.497 15.093 5.206 4.641 2.71 8.702 6.307 11.952 10.586 2.333 3.071 4.215 6.45 5.596 10.035.902 2.34 3.361 3.802 5.787 3.165Z"
                    fill="currentFill"
                  />
                </svg>
              ) : projctDetailName ? (
                "Save"
              ) : (
                "Add Project Sheet"
              )
            }
            buttonType="submit"
            disabled={loading}
            classname={`bg-custom-blue font-semibold text-custom-white px-5 py-1 rounded-md ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default ProjectSheet;
