import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import CommonTooltip from "./CommonTooltip";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const CustomSidebar = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        // Tailwind's `sm` breakpoint is 640px
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    };

    handleResize(); // Set initial state based on current width

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const matchRoute = (item) => {
    if (!item) return false;
    const { path, active } = item;

    const isPathMatch = path ? matchPath(path, location?.pathname) : false;
    const isActiveMatch = active
      ? active.some((activePath) => matchPath(activePath, location?.pathname))
      : false;

    return isPathMatch || isActiveMatch;
  };

  useEffect(() => {
    if (
      location?.pathname === "/department" ||
      location?.pathname === "/designation" ||
      location?.pathname === "/office_location" ||
      location?.pathname === "/company-profile"
    ) {
      setOpenSubItems(true);
    }
  }, [location]);

  const handleOpenMenuItems = (index) => {
    setOpenSubItems((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  return (
    <div
      className={`${
        isCollapsed ? "w-16" : "w-64"
      } h-full border-r shadow-md bg-custom-white transition-all duration-500 relative`}
    >
      {/* Menu Items */}
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`${index === 0 ? "border-t border-b" : "border-b"}`}
        >
          <div
            className={`${
              matchRoute(item) &&
              "text-custom-blue bg-[#E3EFF8] border-l-4 border-l-custom-blue"
            } flex items-center ${
              isCollapsed ? "justify-center" : "justify-between"
            } gap-4 px-5 py-3 font-medium cursor-pointer`}
            onClick={() => {
              if (item?.subItems) {
                // setOpenSubItems((prev) => !prev);
                handleOpenMenuItems(index);
              } else {
                setOpenSubItems({});
                item?.path && navigate(item.path);
              }
            }}
          >
            <div className="flex items-center gap-4">
              <span className="text-xl">
                <CommonTooltip tooltip={isCollapsed ? item?.name : ""}>
                  {item?.icon}
                </CommonTooltip>
              </span>
              {!isCollapsed && <span>{item?.name}</span>}
            </div>
            {!isCollapsed && item?.subItems && (
              <span>{openSubItems ? <FaAngleUp /> : <FaAngleDown />}</span>
            )}
          </div>
          {/* Sub Items */}
          <div
            className={`overflow-hidden transition-all duration-500 ease-linear ${
              openSubItems[index] ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            {item?.subItems &&
              openSubItems &&
              item?.subItems?.map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  className={`${
                    matchRoute(subItem) &&
                    "text-custom-blue bg-[#E3EFF8] border-l-4 border-custom-blue"
                  } flex items-center ${
                    isCollapsed ? "justify-center" : "justify-between"
                  } gap-4 px-8 py-3 font-medium cursor-pointer`}
                  onClick={() => {
                    subItem?.path && navigate(subItem?.path);
                  }}
                >
                  <div className="flex items-center gap-4">
                    <span className="text-xl">
                      <CommonTooltip tooltip={isCollapsed ? subItem?.name : ""}>
                        {subItem?.icon}
                      </CommonTooltip>
                    </span>
                    {!isCollapsed && <span>{subItem?.name}</span>}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
      {/* Toggle Button */}
      <div className="w-full bg-gradient-custom-light-blue absolute bottom-0 right-0">
        <button
          onClick={() => setIsCollapsed((prev) => !prev)}
          className={`text-custom-blue w-10 h-10 rounded-full focus:outline-none p-2 flex items-center justify-center cursor-pointer`}
        >
          {isCollapsed ? (
            <MdKeyboardArrowRight size={44} fontSize={24} />
          ) : (
            <MdKeyboardArrowLeft size={44} fontSize={24} />
          )}
        </button>
      </div>
    </div>
  );
};

export default CustomSidebar;
