import React, { useContext, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { dateFormatter } from "../../helper/formatDate";
import ProjectHeading from "../project/Heading";
import { CustomAssignees, CustomButton, CustomModal } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { meetingEndPoints } from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/loadingSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { CustomAlertContext } from "../../context";
import dayjs from "dayjs";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const MeetingView = ({
  setMeetingDetails,
  meetingDetails,
  MeetingStatusHandler,
  setShowMeetingView,
  setShowMeetingForm,
  setShowRejectModal,
}) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);
  const meetingParticipants = meetingDetails?.participants?.map((item) => ({
    _id: item?.user?._id,
    is_invitation_accepted: item?.is_invitation_accepted,
    user_name: item?.user?.user_name,
    designation: item?.user?.designation,
    avatar: item?.user?.avatar,
  }));
  console.log(new Date(meetingDetails?.date_time));
  console.log(new Date());

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center pb-4 border-b border-slate-200">
        <h1 className="text-lg font-semibold">Meeting</h1>

        {meetingDetails?.meeting_organizer?._id === user?._id && (
          <div
            onClick={() => setShowMeetingForm(true)}
            className="p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer"
          >
            <MdOutlineEdit size={16} />
          </div>
        )}
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
        <ProjectHeading label={"Title"} value={meetingDetails?.title ?? "--"} />
        <ProjectHeading
          label={"Agenda"}
          value={meetingDetails?.agenda ?? "--"}
        />
        <ProjectHeading
          label={"Date & Time"}
          value={dateFormatter(meetingDetails?.date_time, {
            format: "MMM DD,YYYY HH:MM:A",
            includeTimeForToday: true,
          })}
        />
        <ProjectHeading
          label={"Duration"}
          value={meetingDetails?.meeting_duration ?? "--"}
        />
        <ProjectHeading
          label={"Mode of Meeting"}
          value={meetingDetails?.mode_of_meeting ?? "--"}
        />
        <ProjectHeading
          link={meetingDetails?.mode_of_meeting === "online" ? true : false}
          label={
            meetingDetails?.mode_of_meeting === "online"
              ? "Meeting Link"
              : "Meeting Location"
          }
          value={
            meetingDetails?.mode_of_meeting === "online"
              ? meetingDetails?.meeting_link
              : meetingDetails?.meeting_location
          }
        />
        <ProjectHeading
          label={"Meeting Organizer"}
          value={
            <div className="flex items-center gap-2">
              <img
                src={
                  meetingDetails?.meeting_organizer?.avatar
                    ? `${BASE_URL}/${meetingDetails?.meeting_organizer?.avatar}`
                    : `https://api.dicebear.com/5.x/initials/svg?seed=${meetingDetails?.meeting_organizer?.user_name}`
                }
                alt={meetingDetails?.meeting_organizer?.user_name?.[0]}
                className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
              />
              <span className="text-xs">
                {meetingDetails?.meeting_organizer?._id === user?._id
                  ? "You"
                  : meetingDetails?.meeting_organizer?.user_name}
              </span>
            </div>
          }
        />
        <ProjectHeading
          label={"Participants"}
          value={
            (
              <CustomAssignees
                meeting={true}
                assignees={meetingParticipants}
                maxLimit={meetingDetails?.participants?.length}
              />
            ) ?? "--"
          }
        />
      </div>
      <ProjectHeading label={"Result"} value={meetingDetails?.result ?? "--"} />
      <div className="text-sm text-slate-400 font-semibold">
        <ProjectHeading
          label={"Description"}
          value={meetingDetails?.description ?? "--"}
        />
      </div>
      {meetingDetails?.participants?.some(
        (item) =>
          item?.user?._id === user?._id &&
          typeof item?.is_invitation_accepted !== "boolean"
      ) &&
        dayjs.utc().isBefore(dayjs.utc(meetingDetails?.date_time)) && (
          <div className="flex justify-end items-center gap-5 mb-2">
            <CustomButton
              title={"Accept"}
              buttonType="button"
              onClick={() => {
                MeetingStatusHandler({ invitation: "accepted" });
              }}
              classname="bg-green-500 text-sm font-semibold text-custom-white px-5 py-1 rounded-md"
            />
            <CustomButton
              title={"Reject"}
              buttonType="button"
              onClick={() => {
                setShowMeetingView(false);
                setShowRejectModal(true);
              }}
              classname="bg-red-500 text-sm font-semibold text-custom-white px-5 py-1 rounded-md"
            />
          </div>
        )}
      <div className="w-full border-t border-slate-300">
        <h1 className="text-lg font-semibold">Rejected By</h1>
        <div className="">
          {meetingDetails?.participants?.map(
            (item) =>
              typeof item?.is_invitation_accepted === "boolean" &&
              !item?.is_invitation_accepted && (
                <div
                  key={item?._id}
                  className="bg-[#FFE3E3] p-2 m-2 rounded-md"
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={
                        item?.user?.avatar
                          ? `${BASE_URL}/${item?.user?.avatar}`
                          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user?.user_name}`
                      }
                      alt={item?.user?.user_name?.[0]}
                      className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                    />
                    <span className="text-xs">
                      {item?.user?._id === user?._id
                        ? "You"
                        : item?.user?.user_name}
                    </span>
                  </div>
                  <div className="text-sm font-semibold ">
                    {item?.rejection_reason}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default MeetingView;
