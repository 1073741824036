import React, { useContext, useEffect, useState } from "react";
import { CustomButton, CustomInput, CustomSelect } from "../common";
import { MdKeyboardArrowDown, MdOutlineEventNote } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/loadingSlice";
import { milestoneEndPoints, taskEndPoints } from "../../networking/Endpoints";
import { apiConnector } from "../../networking/ApiConnector";
import { toast } from "react-toastify";
import {
  getAllProject,
  getAllProjectMilestone,
} from "../../redux/slice/projectSlice";

import { priorityOptions, Roles, statusOptions } from "../../constant/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomAlertContext } from "../../context";
const baseUrl = process.env.REACT_APP_BASE_URL;
const taskValidation = yup.object().shape({
  title: yup.string().required("Task Name is required").trim(),
  project: yup.string().required("Project is required"),
  milestone: yup.string().required("Milestone is required"),
  start_date: yup.string().required("Start Date is required"),
  status: yup.string().notRequired(),
  assign_to: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "You must select at least one member")
    .required("Assign Task to Members"),
  estimated_time: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Estimated Time must be in the format HH:MM"
    )
    .required("Estimated Time is required"),
  hours_logged: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Hours Logged must be in the format HH:MM"
    )
    .when("status", {
      is: (status) => {
        return status === "Completed";
      },
      then: (schema) => schema.required("Hours Logged is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  completed_on: yup.string().when("status", {
    is: (status) => {
      return status === "Completed";
    },
    then: (schema) => schema.required("Completed_on is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .when("status", {
      is: (status) => {
        return status === "Completed";
      },
      then: (schema) =>
        schema
          .min(1, "Completed_by is required")
          .required("Completed_by is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

const TaskForm = () => {
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  const { token, user } = useSelector((state) => state.auth),
    { loading } = useSelector((state) => state.loading),
    { projectData } = useSelector((state) => state.project),
    [showOtherDetails, setShowOtherDetails] = useState(false),
    { setToastNotification } = useContext(CustomAlertContext),
    [isEditMode, setIsEditMode] = useState(false),
    location = useLocation(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    [projectMileStones, setProjectMilestones] = useState([]),
    defaultProject = location?.state?.project,
    task = location?.state?.task;
  useEffect(() => {
    if (task) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [task]);
  const {
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(taskValidation),
    defaultValues: {
      title: task?.title ? task?.title : "",
      project: defaultProject
        ? defaultProject
        : task?.project
        ? task?.project?._id
        : "",
      start_date: task?.start_date
        ? task?.start_date?.split("T")[0]
        : undefined,
      due_date: task?.due_date ? task?.due_date?.split("T")[0] : undefined,
      status: task?.status ? task?.status : undefined,
      priority: task?.priority ? task?.priority : undefined,
      milestone: task?.milestone ? task?.milestone?._id : undefined,
      assign_to: task?.assign_to?.map((item) => item?._id) ?? [],
      description: task?.description ? task?.description : undefined,
      estimated_time: task?.estimated_time ? task?.estimated_time : undefined,
      hours_logged: task?.hours_logged ? task?.hours_logged : undefined,
      completed_by: task?.completed_by
        ? task?.completed_by?.map((item) => item?._id)
        : undefined,
      completed_on: task?.completed_on
        ? task?.completed_on?.split("T")[0]
        : undefined,
    },
  });
  const fetchProjects = async () => {
    try {
      await dispatch(getAllProject({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  useEffect(() => {
    if (!projectData || (projectData?.page_number && projectData?.page_size)) {
      fetchProjects();
    }
  }, []);

  const projectOptions = projectData?.projects
    ?.filter((project) => project?.projectStatus !== "Cancelled")
    ?.map((project) => ({
      name: project?.project_name,
      value: project?._id,
    }));
  const selectedStatus = watch("status");
  const selectedProject = watch("project");
  const selectedMilestone = watch("milestone");
  const selectedTaskStartDate = watch("start_date");
  const selectedTaskEndDate = watch("due_date");

  useEffect(() => {
    if (selectedProject) {
      setError("assign_to", {
        type: "manual",
        message: "",
      });
      setError("milestone", {
        type: "manual",
        message: "",
      });
    }
  }, [selectedProject]);

  const fetchMilestone = async () => {
    try {
      const projectId = selectedProject;
      const response = await dispatch(
        getAllProjectMilestone({
          token,
          projectId,
        })
      ).unwrap();

      if (response?.data) {
        setProjectMilestones(response?.data?.milestone);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    if (selectedProject) {
      fetchMilestone();
    }
  }, [selectedProject]);

  const milestoneOptions = projectMileStones?.map((item) => ({
    name: item?.milestone_name,
    value: item?._id,
  }));

  const selectedProjectMilestone = projectMileStones?.filter(
    (milestone) => milestone?._id === selectedMilestone
  );

  const selected_project_milestone_start_date =
    selectedProjectMilestone[0]?.start_date?.replaceAll(",", "-");
  const selected_project_milestone_deadline =
    selectedProjectMilestone[0]?.deadline_date?.replaceAll(",", "-");

  const selectedProjectMember = projectData?.projects?.filter(
    (project) =>
      project?._id === defaultProject || project?._id === selectedProject
  );

  let assignToOptions = [];
  if (selectedProjectMember?.length && user?.roleId === Roles?.admin) {
    assignToOptions.push({
      name: selectedProjectMember?.[0]?.project_manager?.user_name,
      value: selectedProjectMember?.[0]?.project_manager?._id,
      designation:
        selectedProjectMember?.[0]?.project_manager?.designation?.designation,
      avatar: selectedProjectMember?.[0]?.project_manager?.avatar
        ? `${baseUrl}/${selectedProjectMember?.[0]?.project_manager?.avatar}`
        : `https://api.dicebear.com/5.x/initials/svg?seed=${selectedProjectMember?.[0]?.project_manager?.user_name}`,
    });
  }
  if (
    selectedProjectMember?.length &&
    (user?.roleId === Roles?.admin || user?.roleId === Roles?.project_manager)
  ) {
    selectedProjectMember?.[0]?.team_lead?.map((item) =>
      assignToOptions.push({
        name: item?._id === user?._id ? "You" : item?.user_name,
        value: item?._id,
        designation: item?.designation?.designation,
        avatar: item?.avatar
          ? `${baseUrl}/${item?.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
      })
    );
  }

  selectedProjectMember?.[0]?.members?.map((item) =>
    assignToOptions.push({
      name: item?.user_name,
      value: item?._id,
      designation: item?.designation?.designation,
      avatar: item?.avatar
        ? `${baseUrl}/${item?.avatar}`
        : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
    })
  );
  if (task) {
    task?.assign_to?.map((member) => {
      const isAlreadyAssigned = assignToOptions.some(
        (item) => item.value === member?._id
      );
      if (!isAlreadyAssigned) {
        assignToOptions.push({
          name: member?._id === user?._id ? "You" : member?.user_name,
          value: member?._id,
          designation: member?.designation?.designation,
          avatar: member?.avatar
            ? `${baseUrl}/${member?.avatar}`
            : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
        });
      }
    });
  }

  const selfTaskAssign = assignToOptions?.some(
    (item) => item?.value === user?._id
  );

  if (!selfTaskAssign) {
    assignToOptions.push({
      name: "You",
      value: user?._id,
      designation: user?.designation?.designation,
      avatar: user?.avatar
        ? `${baseUrl}/${user?.avatar}`
        : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`,
    });
  }

  const task_assignees = watch("assign_to");

  const completed_by_options = assignToOptions?.filter((item) => {
    if (task_assignees.includes(item?.value)) {
      return {
        name: item?.name,
        value: item?.value,
        designation: item?.designation,
        avatar: item?.avatar
          ? `${baseUrl}/${item?.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
      };
    }
  });

  const onSubmitHandler = async (data) => {
    let payload = isEditMode
      ? Object.keys(data).reduce((acc, key) => {
          if (key === "assign_to" || key === "completed_by") {
            // Deep comparison for assign_to
            const currentAssignTo = data[key].sort();
            const previousAssignTo = task[key]?.map((item) => item?._id).sort();

            if (
              JSON.stringify(currentAssignTo) !==
              JSON.stringify(previousAssignTo)
            ) {
              acc[key] = data[key];
            }
          } else if (key === "project" || key === "milestone") {
            // Shallow comparison for project
            if (data[key] !== task[key]?._id) {
              acc[key] = data[key];
            }
          } else if (
            key === "start_date" ||
            key === "due_date" ||
            key === "completed_on"
          ) {
            // Normalize date comparison by converting to ISO string
            const dataDate = data[key]
              ? new Date(data[key]).toISOString()
              : null;
            const prevDate = task[key]
              ? new Date(task[key]).toISOString()
              : null;

            if (dataDate !== prevDate) {
              acc[key] = data[key];
            }
          } else if (data[key] !== task[key]) {
            // Shallow comparison for other fields
            acc[key] = data[key];
          }
          return acc;
        }, {})
      : {
          title: data?.title ?? undefined,
          project: data?.project ? data?.project : undefined,
          milestone: data?.milestone ? data?.milestone : "",
          start_date: data?.start_date ? data?.start_date : undefined,
          due_date: data?.due_date ? data?.due_date : undefined,
          status: data?.status ? data?.status : undefined,
          priority: data?.priority ? data?.priority : undefined,
          assign_to: data?.assign_to ?? [],
          description: data?.description ? data?.description : undefined,
          estimated_time: data?.estimated_time
            ? data?.estimated_time
            : undefined,
          hours_logged: data?.hours_logged ? data?.hours_logged : undefined,
          completed_by: data?.completed_by ? data?.completed_by : undefined,
          completed_on: data?.completed_on ? data?.completed_on : undefined,
        };

    dispatch(setLoading(true));
    try {
      const apiMethod = isEditMode ? "PUT" : "POST";
      const apiURl = isEditMode
        ? `${taskEndPoints?.TASK_API}/${task?._id}`
        : taskEndPoints?.TASK_API;
      const task_response = await apiConnector(apiMethod, apiURl, payload, {
        Authorization: `Bearer ${token}`,
      });
      toast.success(task_response?.data?.message);
      navigate(-1);
      reset();
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className=" w-full border bg-custom-white rounded-md">
      <form
        className="w-full p-6 "
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="text-xl font-semibold text-custom-black">
          {isEditMode ? "Edit Task" : "Add Task"}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="project"
              label="Project"
              inputType="text"
              placeholder="Selectc a project"
              control={control}
              required={true}
              options={projectOptions}
              error={errors?.project?.message}
              isEditable={defaultProject ? true : task?.project ? true : false}
            />
          </div>
          <div
            onClick={() => {
              if (!selectedProject) {
                setError("milestone", {
                  type: "manual",
                  message: "Note:- Please Select a Project First!",
                });
              }
            }}
            className="my-2 sm:my-4"
          >
            <CustomSelect
              name="milestone"
              label="Milestone"
              inputType="text"
              placeholder="Select a milestone"
              control={control}
              required={true}
              options={milestoneOptions}
              error={errors?.milestone?.message}
              isEditable={!selectedProject}
            />
          </div>
          <div className="my-2 sm:my-4">
            <CustomInput
              name="title"
              label="Task Name"
              inputType="text"
              placeholder="Task Name"
              control={control}
              required={true}
              error={errors?.title?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="start_date"
              label="Start Date"
              inputType="date"
              placeholder="Task start date"
              control={control}
              required={true}
              error={errors?.start_date?.message}
              max={selected_project_milestone_deadline ?? ""}
              min={selected_project_milestone_start_date ?? ""}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="due_date"
              label="Due Date"
              inputType="date"
              placeholder="Task deadline"
              max={selected_project_milestone_deadline ?? ""}
              min={
                selectedTaskStartDate
                  ? selectedTaskStartDate
                  : selected_project_milestone_start_date ?? ""
              }
              control={control}
            />
          </div>

          <div className="my-2 sm:my-4">
            <CustomSelect
              name="status"
              label="Status"
              inputType="text"
              placeholder="Select a status"
              control={control}
              options={statusOptions}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="priority"
              label="Priority"
              inputType="text"
              placeholder="Select a priority"
              control={control}
              options={priorityOptions}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="estimated_time"
              label="Estimated Time"
              inputType="text"
              placeholder="00:00"
              control={control}
              required={true}
              error={errors?.estimated_time?.message}
            />
          </div>
          <div
            onClick={() => {
              if (!selectedProject) {
                setError("assign_to", {
                  type: "manual",
                  message: "Note:- Please Select a Project First!",
                });
              }
            }}
            className="my-2 sm:my-4"
          >
            <CustomSelect
              name="assign_to"
              label="Assign To"
              inputType="text"
              placeholder="Select a assignee"
              control={control}
              required={true}
              multiple="true"
              options={assignToOptions}
              isEditable={!selectedProject}
              error={errors?.assign_to?.message}
            />
          </div>
        </div>
        <div className="my-2 sm:my-4">
          <CustomInput
            name="description"
            label="Task Description"
            inputType="text-area"
            placeholder="Task description"
            control={control}
          />
        </div>
        <div
          onClick={() => setShowOtherDetails(!showOtherDetails)}
          className="my-2 sm:my-4  border rounded-lg bg-[#F5FBFF] cursor-pointer"
        >
          <div
            className={`flex justify-between items-center p-3 ${
              showOtherDetails ? "border-b" : ""
            }`}
          >
            <h2 className="font-semibold">Other Details</h2>
            <MdKeyboardArrowDown size={20} />
          </div>
          {(showOtherDetails || selectedStatus === "Completed") && (
            <div
              onClick={(e) => e.stopPropagation()}
              className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5"
            >
              <div className="my-2 sm:my-4 ">
                <CustomInput
                  name="hours_logged"
                  label="Hours Logged"
                  inputType="text"
                  placeholder="00:00"
                  control={control}
                  error={errors?.hours_logged?.message}
                />
              </div>

              <div className="my-2 sm:my-4 ">
                <CustomSelect
                  name="completed_by"
                  label="Completed By"
                  inputType="text"
                  placeholder="Select a assignee"
                  control={control}
                  multiple={true}
                  options={completed_by_options}
                  classname="bg-custom-white"
                  error={errors?.completed_by?.message}
                />
              </div>
              <div className="my-2 sm:my-4 ">
                <CustomInput
                  name="completed_on"
                  label="Completed On"
                  inputType="date"
                  placeholder="Select a date"
                  control={control}
                  error={errors?.completed_on?.message}
                  min={selectedTaskStartDate}
                  max={formattedDate}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center my-10 gap-4">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => {
              navigate(-1);
            }}
            classname={`border font-semibold text-custom-black px-5 py-1.5 rounded-md `}
          />
          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            buttonType="submit"
            disabled={loading}
            classname={`bg-green-500 font-semibold text-custom-white px-5 py-1.5 rounded-md ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default TaskForm;
