import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomAlertContext } from "../../context";
import { chartColors, Roles } from "../../constant/Constant";
import {
  FaBuilding,
  FaCheckCircle,
  FaFilePowerpoint,
  FaRegFileAlt,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { TbXboxXFilled } from "react-icons/tb";
import { getAllProject } from "../../redux/slice/projectSlice";
import { BsDiagram2 } from "react-icons/bs";
import {
  CommonTooltip,
  CustomButton,
  CustomChart,
  CustomStatusButton,
  TableHeader,
} from "../common";
import {
  dateFormatter,
  getDateDifferenceStatus,
  groupByDate,
} from "../../helper/formatDate";
import { apiConnector } from "../../networking/ApiConnector";
import {
  dashboardEndPoints,
  meetingEndPoints,
} from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/loadingSlice";
import {
  CardSkeleton,
  DashboardBirthdayCard,
  DashboardJoiningCard,
  DashboardMeetingCard,
  DashboardProfileCard,
  DashboardProjectTable,
  TableSkeleton,
} from "./CommonComponent";
import { RiProgress3Line, RiProgress4Fill } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
const Base_url = process.env.REACT_APP_BASE_URL;

const project_labels = [
  "Not Started",
  "In Progress",
  "On Hold",
  "Completed",
  "Cancelled",
];

const projectColors = [
  chartColors?.not_started,
  chartColors?.in_progress,
  chartColors?.on_hold,
  chartColors?.completed,
  chartColors?.cancelled,
];
const task_labels = [
  "Not Started Task",
  "In Progress Task",
  "On Hold Task",
  "Completed Task",
  "Cancelled Task",
];
const task_colors = [
  chartColors?.not_started,
  chartColors?.in_progress,
  chartColors?.on_hold,
  chartColors?.completed,
  chartColors?.cancelled,
];
const employeeColors = [
  "#6F73E4",
  "#E9B5B5",
  "#FFDE08",
  "#C2D732",
  "#0E9ABF",
  "#6F58A4",
  "#FF2568",
  "#00A0D0",
  "#B547A6",
  "#00B5B0",
  "#08C5FF",
  "#747474",
];
const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const [projectData, setProjectData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeLabels, setEmployeeLabels] = useState([]);
  const { token, user } = useSelector((state) => state.auth);
  const { projectLoading } = useSelector((state) => state.project);
  const { loading } = useSelector((state) => state.loading);
  const { setToastNotification } = useContext(CustomAlertContext);
  const [taskData, setTaskData] = useState([]);
  const [meetingsData, setMeetingsData] = useState([]);
  const [meetingsLoading, setMeetingsLoading] = useState(false);
  const currentPage = 1;
  const itemsPerPage = 5;

  useEffect(() => {
    fetchProjects();
    GetDashboardDetails();
    GetUpcomingMeetings();
  }, []);
  const fetchProjects = async (
    department,
    project_member,
    project_manager,
    projectStatus,
    priority
  ) => {
    const queryParams = {};
    if (department) queryParams.department = department;
    if (project_member) queryParams.members = project_member;
    if (project_manager) queryParams.project_manager = project_manager;
    if (projectStatus) queryParams.projectStatus = projectStatus;
    if (priority) queryParams.priority = priority;
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    try {
      const response = await dispatch(
        getAllProject({
          token,
          queryParams,
        })
      ).unwrap();

      if (response) {
        setProjects(response?.projects);

        // setProjectCount(response?.project_count);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  const GetDashboardDetails = async () => {
    dispatch(setLoading(true));
    try {
      const dashboard_response = await apiConnector(
        "GET",
        dashboardEndPoints?.DASHBOARD_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setDashboardData(dashboard_response?.data?.response);
      setProjectData([
        dashboard_response?.data?.response?.projects["Not Started"] ?? 0,
        dashboard_response?.data?.response?.projects["In Progress"] ?? 0,
        dashboard_response?.data?.response?.projects["On Hold"] ?? 0,
        dashboard_response?.data?.response?.projects["Completed"] ?? 0,
        dashboard_response?.data?.response?.projects["Cancelled"] ?? 0,
      ]);
      setTaskData([
        dashboard_response?.data?.response?.task?.not_started_task ?? 0,
        dashboard_response?.data?.response?.task?.in_progress_task ?? 0,
        dashboard_response?.data?.response?.task?.on_hold_task ?? 0,
        dashboard_response?.data?.response?.task?.completed_task ?? 0,
        dashboard_response?.data?.response?.task?.cancelled_task ?? 0,
      ]);
      const labels = [];
      const data = [];
      for (let [key, value] of Object.entries(
        dashboard_response?.data?.response?.employees || {}
      )) {
        if (key !== "total_employees") {
          labels.push(key);
          data.push(value);
        }
      }

      setEmployeeLabels(labels);
      setEmployeeData(data);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const GetUpcomingMeetings = async () => {
    setMeetingsLoading(true);
    try {
      const meeting_response = await apiConnector(
        "GET",
        meetingEndPoints?.UPCOMING_MEETING_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setMeetingsData(meeting_response?.data?.meetings);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      setMeetingsLoading(false);
    }
  };
  const groupedBirthdays = groupByDate(
    dashboardData?.birthdays,
    "nextBirthday"
  );
  const groupedJoinings = groupByDate(dashboardData?.userEvent, "joining_date");
  const RandomEmployeeColor = [];
  for (let i = 0; i < employeeLabels?.length; i++) {
    RandomEmployeeColor.push(employeeColors[i]);
  }
  return (
    <div>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        {loading ? (
          <div className="w-full lg:w-1/4 col-span-2">
            <CardSkeleton height="lg:h-80" />
          </div>
        ) : (
          <DashboardProfileCard dashboardData={dashboardData} />
        )}
        {loading ? (
          <section className="w-full border p-2 rounded-md lg:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-4">
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <CardSkeleton key={index} />
              ))}
          </section>
        ) : (
          <section className="w-full border p-2 rounded-md lg:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className=" bg-[#DBF0FF] rounded-md p-4 flex items-center gap-2">
              <div className="w-14 h-14 bg-custom-white border border-blue-300 text-custom-blue rounded-full text-2xl font-semibold flex justify-center items-center">
                <FaFilePowerpoint />
              </div>
              <div className="flex flex-col justify-center">
                <span className="text-sm font-semibold">Total Projects</span>
                <span className="text-xl font-semibold">
                  {dashboardData?.projects?.["Total Projects"] ?? "--"}
                </span>
              </div>
            </div>
            <div className="bg-[#FFE9C4] rounded-md p-4 flex items-center gap-2">
              <div className="w-14 h-14 bg-custom-white border border-yellow-300 text-[#FFC200] rounded-full text-2xl font-semibold flex justify-center items-center">
                <RiProgress3Line />
              </div>
              <div className="flex flex-col justify-center">
                <span className="text-sm font-semibold">
                  In Progress Projects
                </span>
                <span className="text-xl font-semibold">
                  {dashboardData?.projects["In Progress"] ?? "--"}
                </span>
              </div>
            </div>
            <div className="bg-[#DBF0FF] rounded-md p-4 flex items-center gap-2">
              <div className="w-14 h-14 bg-custom-white border border-blue-300 text-custom-blue rounded-full text-2xl font-semibold flex justify-center items-center">
                <FaRegFileAlt />
              </div>
              <div className="flex flex-col justify-center">
                <span className="text-sm font-semibold">Total Task</span>
                <span className="text-xl font-semibold">
                  {dashboardData?.task?.total_task ?? "--"}
                </span>
              </div>
            </div>
            <div className="bg-[#FFE9C4] rounded-md p-4 flex items-center gap-2">
              <div className="w-14 h-14 bg-custom-white border border-yellow-300 text-[#FFC200] rounded-full text-2xl font-semibold flex justify-center items-center">
                <RiProgress4Fill />
              </div>
              <div className="flex flex-col justify-center">
                <span className="text-sm font-semibold">In Progress Task</span>
                <span className="text-xl font-semibold">
                  {dashboardData?.task?.in_progress_task ?? "--"}
                </span>
              </div>
            </div>
          </section>
        )}
        <div className="w-full lg:w-1/4 border rounded-md p-2">
          <h2>Meetings</h2>
          {meetingsLoading ? (
            <div className="border rounded-md p-2 row-span-1 space-y-2">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <CardSkeleton key={index} />
                ))}
            </div>
          ) : (
          <DashboardMeetingCard meetingsData={meetingsData} />
          )}
        </div>
      </div>
      <section className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4 mt-3">
        <div className="w-full col-span-2">
          <div className="flex justify-between items-center">
            <h2>Projects</h2>
            <CustomButton
              title={"View More"}
              onClick={() => navigate("/project")}
              classname={
                "text-xs font-semibold text-custom-blue bg-custom-light-blue p-1 rounded-md"
              }
            />
          </div>
          <div className="w-full mt-2">
            {projectLoading ? (
              <TableSkeleton columns={5} rows={5} />
            ) : (
              <DashboardProjectTable projects={projects} />
            )}
          </div>
        </div>
        <div className="w-full space-y-3">
          <div className="relative border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center h-full md:h-[340px]">
            <h1 className="font-semibold text-sm my-2 text-slate-500">
              Projects
            </h1>
            {loading ? (
              <Skeleton width={250} height={150} />
            ) : dashboardData?.projects["Total Projects"] > 0 ? (
              <CustomChart
                labelName={""}
                chartType="doughnut"
                labels={project_labels}
                data={projectData}
                colors={projectColors}
              />
            ) : (
              <span>No Project Available</span>
            )}
            <h1>
              Total Project -{" "}
              {dashboardData?.projects["Total Projects"] ?? "--"}
            </h1>
          </div>
        </div>
        {loading ? (
          <div className="border rounded-md p-2 row-span-1 space-y-2">
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <CardSkeleton key={index} />
              ))}
          </div>
        ) : (
          <DashboardBirthdayCard groupedBirthdays={groupedBirthdays} />
        )}
      </section>
      <section className="w-full grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-3">
        <div className="">
          <div className="relative border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center h-full md:h-80">
            <h1 className="font-semibold text-sm my-2 text-slate-500">
              Employee Statistics
            </h1>
            {loading ? (
              <Skeleton width={250} height={150} />
            ) : dashboardData?.employees?.total_employees > 0 ? (
              <CustomChart
                labelName={""}
                chartType="pie"
                labels={employeeLabels}
                data={employeeData}
                colors={RandomEmployeeColor}
              />
            ) : (
              <span>No Employees Available</span>
            )}

            <h1>
              Total Employees -{" "}
              {dashboardData?.employees?.total_employees ?? "--"}
            </h1>
          </div>
        </div>
        <div className="">
          <div className="relative border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center h-full md:h-80">
            <h1 className="font-semibold text-sm my-2 text-slate-500">
              Task Statistics
            </h1>
            {loading ? (
              <Skeleton width={250} height={150} />
            ) : dashboardData?.task?.total_task > 0 ? (
              <CustomChart
                labelName={""}
                chartType="pie"
                labels={task_labels}
                data={taskData}
                colors={task_colors}
              />
            ) : (
              <span>No Task Available</span>
            )}
            <h1>Total Tasks - {dashboardData?.task?.total_task ?? "--"}</h1>
          </div>
        </div>
        <div className="w-full border rounded-md p-2">
          <h2 className="font-semibold text-sm text-slate-500">
            Joining & Work Anniversary
          </h2>
          {loading ? (
            <div className="border rounded-md p-2 row-span-1 space-y-2">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <CardSkeleton key={index} />
                ))}
            </div>
          ) : (
            <DashboardJoiningCard groupedJoinings={groupedJoinings} />
          )}
        </div>
      </section>
    </div>
  );
};

export default AdminDashboard;
